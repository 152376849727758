import request from '@/http/request'
import wayCode from "@/utils/wayCode";
import channelUserIdUtil from "@/utils/channelUserId";
import config from '@/config'

/** 获取url **/
export function getRedirectUrl () {
  return request.request({
    url: '/api/cashier/redirectUrl',
    method: 'POST',
    data: { wayCode: wayCode.getPayWay().wayCode, token: config.cacheToken }
  })
}

/** 获取url **/
export function getChannelUserId (redirectData) {
  return request.request({
    url: '/api/cashier/channelUserId',
    method: 'POST',
    data: Object.assign({ wayCode: wayCode.getPayWay().wayCode, token: config.cacheToken }, redirectData)
  })
}

/** 调起支付接口, 获取支付数据包 **/
export function getPayPackage (amount) {
  return request.request({
    url: '/api/cashier/pay',
    method: 'POST',
    data: {
      wayCode: wayCode.getPayWay().wayCode,
      token: config.cacheToken,
      amount: amount,
      channelUserId: channelUserIdUtil.getChannelUserId()
    }
  })
}

/** 调起支付接口, 获取订单信息 **/
export function getPayOrderInfo () {
  return request.request({
    url: '/api/cashier/payOrderInfo',
    method: 'POST',
    data: {
      token: config.cacheToken
    }
  })
}

/** 取消订单 **/
export function cancelPay () {
  return request.request({
    url: '/api/cashier/cancelPay',
    method: 'POST',
    data: {
      token: config.cacheToken
    }
  })
}

/** 
   * 校验付款码状态，返回信息包括静态码名称，支付配置的一些信息，
   * 二维码状态（启用/禁用），
   * secret(每次请求唯一生成，用来下一步调用支付接口做校验用，比如交易是否超时，
   * 先设置5分钟请求有效吧) 
**/
export function validPayQrcode (qrCode) {
  return request.request({
    url: '/app-api/bms/pay-code-config/get/pay-info',
    method: 'GET',
    params: {
      code: 'bms_pay',
      qrCode
    }
  })
}

/** 
  用户填写支付金额，备注，点击支付调用支付接口，接口传参：code，qrCode，bizType，tenantId，amount，memo，secret,
  支付下单后返回
  {
    mchOrderNo
    orderState
    payData
    payDataType
    payOrderId
  } 
**/
export function qrcodePayment (data) {
  return request.request({
    url: '/app-api/bms/pay-code-config/qrcode/payment',
    method: 'POST',
    data: data
  })
}